import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const listStream = (pageNo) => {
  return ajax.get(`${base}/api/itedu/v1/live/query?pageNo=${pageNo}`);
};

const addStream = (param) => {
  return ajax.post(`${base}/api/itedu/v1/live/generate`, param);
};

const addRecord = (param) => {
  return ajax.post(`${base}/api/itedu/v1/live/createRecordTask`, param);
};

const listRecord = (pageNo) => {
  return ajax.get(`${base}/api/itedu/v1/record/query?pageNo=${pageNo}`);
};


export const liveApi = {
  listStream: listStream,
  addStream: addStream,
  addRecord: addRecord,
  listRecord: listRecord
}


