<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" style="margin: 20px; width: 60%; min-width: 600px">
      <el-form-item label="app名称">
        <el-input v-model="form.appName"></el-input>
      </el-form-item>
      <el-form-item label="流名称">
        <el-input v-model="form.streamName"></el-input>
      </el-form-item>
      <el-form-item label="课程ids">
        <el-input v-model="courseListStr" placeholder="英文逗号分隔，不设置代表任何人都可以访问直播"></el-input>
      </el-form-item>

      <el-form-item label="结束时间">
        <el-date-picker type="datetime" placeholder="结束时间" v-model="form.endTime" value-format="timestamp">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { liveApi } from "@/api/liveApi";
import { customAlphabet } from "nanoid";
//import msg from "@/util/msg.js";
export default {
  data() {
    return {
      courseListStr: "",
      form: {
        id: 0,
        appName: "aida101",
        streamName: "",
        courseList: [],
        endTime: Date.now(),
      },
    };
  },
  mounted() {
    const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 8);
    let id = nanoid(); //=> "4f90d13a42"
    this.form.streamName = id;
  },
  methods: {
    onSubmit() {
      if (this.endTime < new Date()) {
        this.$message({
          message: "结束时间必须大于当前时间",
          type: "warn",
        });
        return;
      }

      if (this.courseListStr) {
        this.form.courseList = this.courseListStr.split(",");
      } else {
        this.form.courseList = [];
      }

      liveApi
        .addStream(this.form)
        .then((ret) => {
          let { code, msg } = ret;
          if (code == 0) {
            this.$message({
              message: "增加推流成功",
              type: "success",
            });
          } else {
            this.$message({
              message: msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            messge: "增加推流失败" + err,
            type: "error",
          });
        });
    },
  },
};
</script>
